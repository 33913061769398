.icon{
    display: inline-block;

    svg{

        height: 20px;
        @include breakpoint($b-lg){
            height: 35px;
        }
    }
}

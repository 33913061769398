.locale-selector{
    background-color: $c-black;
    padding-left: $s-small;
    padding-right: $s-small;
    position: relative;

    z-index: 1;

    &__selected{

        position: relative;
        border-bottom: 3px solid $c-highlight;
        padding-bottom: $s-tight;
        width: 100%;
        z-index: 9;

        padding-right: 20px;
        outline: none;

        &:after{
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            border-top: 8px solid $c-highlight;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            top: 50%;
            transform: translateZ(0) translateY(-50%) rotate(0deg);
            margin-top: -2px;
            right: 0;
            transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);

            @include breakpoint($b-md){
                border-top-size: 10px;
                border-left-size: 7px;
                border-right-size: 7px;
            }

        }
    }

    &--open {
        .locale-selector__selected:after{
            transform: translateZ(0) translateY(-50%) rotate(180deg);
        }
    }

    &__text{
        display: none;

        @include breakpoint($b-mid){
            display: inline-block;
        }
    }

    .transition-height{
        position: absolute;
        top: 100%;
        right: 0;
        z-index: -1;
        background-color: inherit;
    }

     &__locales{
        background-color: inherit;
        padding: $s-small;
        padding-bottom: $s-small + 5px;
        min-width: 205px;

        &:after{
            content: '';
            position: absolute;
            left: $s-small;
            right: $s-small;
            height: $s-small;
            bottom: 0;
            background-color: inherit;
            border-top: 3px solid $c-highlight;
        }
    }

    &__locale,
    &__selected{
        display: block;
        cursor: pointer;

        @include breakpoint($b-md){
            font-size: $f-medium;
        }

    }

    &__locale{
        opacity: 0.5;
        padding-bottom: 10px;
        display: flex;
        outline: none;

        transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);

        &:hover,
        &:focus{
            opacity: 1;
        }
    }

    &__code{
        margin-left: auto;
    }
}

/**
 * Variables
 */

// Colours
$c-white: #fff;
$c-black: #3b3f4a;
$c-dark: darken($c-black, 5);
$c-highlight: #ffd600;
$c-tech: #00bebe;
$c-family: #ff5a6e;
$c-consumer: #8c64ff;
$c-grey: #6b7386;

// Gradients
$g-tech: linear-gradient(45deg, $c-tech 75%, #32d2aa);
$g-family: linear-gradient(45deg, $c-family 75%, #e91e63);
$g-consumer: linear-gradient(45deg, $c-consumer 75%, #bb64ff);
$g-all: linear-gradient(45deg, $c-tech 23.33%, $c-consumer, $c-family 90%);
$g-transparent: linear-gradient(45deg, rgba($c-tech, 0.5) 23.33%, rgba($c-consumer, 0.5), rgba($c-family, 0.5) 90%);

// Type
$f-body: 'Circular-Pro-Book', 'Helvetica', Helvetica, sans-serif;
$f-headings: 'Circular-Pro-Bold', 'Futura', Helvetica, sans-serif;
$f-condensed: 'UniversLTStd-LightUltraCn', 'Arial Narrow';

$f-small: 12px;
$f-medium: 14px;
$f-base: 16px;
$f-mid: 20px;
$f-large: 30px;
$f-xlarge: 40px;
$f-mega: 80px;


// Sizing
$s-tight: 5px;
$s-small: 15px;
$s-base: 20px;
$s-medium: 30px;
$s-large: 35px;
$s-xlarge: 55px;

$s-max-width: 1020px;
$b-sm: 400px;
$b-mid: 600px;
$b-md: 768px;
$b-lg: 900px;
$b-xlg: 1020px;

$b-menu-switch: 769px;

// 500
// 568
// 600
// 650
// 960

.for-print{

    .group-selector,
    .locale-selector,
    .page-footer,
    .keys{
        display: none !important;
    }

    .story--tech{ .story__header{ background: $c-tech; } }
    .story--family{ .story__header{ background: $c-family; } }
    .story--consumer{ .story__header{ background: $c-consumer; } }

    .story__header{
        &:after{
            display: none;
        }
    }


    .sources__source--print-only{
        display: flex;
    }


    .donut{
        // width: 158px !important;
        height: 0;
        padding-bottom: 100%;
        // height: 158px !important;
    }

    .story-chart--4 .story-chart__item--has-keys{
        .donut{
            padding-bottom: 100px;
        }
    }
}

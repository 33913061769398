.donut{
    position: relative;
    font-family: $f-condensed;
    height: 0;
    padding-bottom: 100%;
    // border: 1px solid #fff;

    // Number
    &__percentage-num{

        font-size: 50px;
        @include breakpoint($b-mid){
            font-size: 100px;
        }
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        // border-radius: 50%;
        z-index: 2;
    }

    // Inner circle
    &:before{
        content: '';
        position: absolute;
        top: 50%;
        width: 60%;
        height: 60%;
        transform: translate(-50%, -50%);
        left: 50%;
        background: #fff;
        border-radius: 50%;
        z-index: 1;
    }


    &,
    svg{
        // border-radius: 50%;
    }






    &-appear,
    &-enter{
        svg circle{
            stroke-dasharray: 0 314 !important;
        }
    }

    svg{
        transform: translate3d(0, 0, 0) rotate(-90deg);
        border-radius: 50% 50%;
        position: absolute;
        top: 1px;
        bottom: 1px;
        left: 1px;
        right: 1px;
        width: calc(100% - 2px);
        height: calc(100% - 2px);

        background: lighten(desaturate($c-tech, 50%), 50%);
        background-image: repeating-linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.5) 1px,
            rgba(255, 255, 255, 0) 1px,
            rgba(255, 255, 255, 0) 8px
        );

        .story--tech &,
        .page-content__story--tech &{ background-color: lighten(desaturate($c-tech, 50%), 50%); color: $c-tech;
            circle{ stroke: $c-tech; }
        }
        .story--family &,
        .page-content__story--family &{ background-color: lighten(desaturate($c-family, 50%), 25%); color: $c-family;
            circle{ stroke: $c-family; }
        }
        .story--consumer &,
        .page-content__story--consumer &{ background-color: lighten(desaturate($c-consumer, 50%), 20%); color: $c-consumer;
            circle{ stroke: $c-consumer; }
        }
    }

    circle {
        transition: all 1.5s cubic-bezier(0.23, 1, 0.32, 1);
        fill: rgba(0, 0, 0, 0);
        stroke: red;
        stroke-width: 50;
    }

}


// Offset transitions
@for $i from 0 through 20{
    .story-chart__item:nth-of-type(#{$i}){
        .donut circle,
        .bar-chart__percentage{
            transition-delay: $i * 0.05s;
        }
    }
}

noscript{
    background: $c-dark;
    display: block;
    padding: $s-large;
    color: $c-white;
    height: 100vh;
    display: flex;
    text-align: center;

    div{
        max-width: 400px;
        margin: auto;
    }

    img{
        max-width: 200px;
        margin: 0 auto;
    }

    a{
        border-bottom: 2px solid $c-highlight;
    }

    *{
        // max-width: 100%;
    }
}


.message{
    // text-transform: uppercase;
    display: block;
    margin-bottom: 20px;

    font-family: $f-headings;
    font-weight: 400;

    .story--family &{ color: $c-family; }
    .story--tech &{ color: $c-tech; }
    .story--consumer &{ color: $c-consumer; }
}

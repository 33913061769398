.mega-number{
    font-family: $f-condensed;
    font-weight: 100;
    text-align: left;
    display: block;
    font-size: 110px;
    line-height: 0.76;
    letter-spacing: -0.03em;

    &:after{
        content: '';
        display: inline-block;
    }

    &__percentage{
        display: inline-block;
        font-size: 0.5em;
        margin-left: 0.05em;
        vertical-align: top;
    }
}

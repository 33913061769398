.story{
    padding: $s-small;
    padding-bottom: 0;
    @include breakpoint($b-md){
        padding: $s-large;
        padding-bottom: 0;
    }

    &__header{
        margin-top: -$s-small;
        margin-left: -$s-small;
        margin-right: -$s-small;
        padding: $s-xlarge $s-small $s-base;
        @include breakpoint($b-md){
            margin-right: -$s-large;
            margin-left: -$s-large;
            margin-top: -$s-large;
            padding: $s-xlarge $s-xlarge *2 $s-base $s-large;
        }

        color: $c-white;
        position: relative;
        z-index: 1;

        h1{
            font-size: $f-large;
            margin-bottom: $s-large;

            @include breakpoint($b-md){
                font-size: $f-xlarge;
            }
        }

        &:after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: inherit;
            background-image: linear-gradient(to top right, rgba(255, 255, 255, 0), rgba(255, 255, 0, 0.2));
            z-index: -1;

            @include breakpoint($s-max-width){
                width: calc(((100vw - #{$s-max-width}) / 2) + #{$s-max-width} - 275px);
            }
        }
    }

    // Themed gradients
    &--tech{ .story__header:after{ background-image: $g-tech; } }
    &--family{ .story__header:after{ background-image: $g-family; } }
    &--consumer{ .story__header:after{ background-image: $g-consumer; } }

    &__content{
        padding-top: $s-medium;
    }
}

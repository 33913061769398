.country-navigation{

    margin-bottom: $s-xlarge;
    z-index: 1;

    &__header{
        color: $c-highlight;
        font-size: $f-medium;
    }

    &--menu-open{
        .country-navigation__countries{
            transform: translateY(0%);
        }

        .country-navigation__selected{
            &:after{
                transform: rotate(180deg);
            }
        }
    }

    .transition-height{
        margin-top: -3px;
        border-bottom: 3px solid $c-highlight;
    }

    &__countries{
        position: relative;
        padding-top: 10px;
        z-index: 1;
    }

    &__selected{
        position: relative;
        border-bottom: 3px solid $c-highlight;
        padding-top: $s-tight;
        padding-bottom: $s-tight;
        width: 100%;
        z-index: 9;
        text-align: left;

        // opacity: 0.7;
        transition: all 0.2s ease-out;
        outline: none;

        &:after{
            content: '';
            width: 0;
            height: 0;
            position: absolute;
            border-top: 10px solid $c-highlight;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            top: $s-small;
            right: 0;
            transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);
        }

        &:hover,
        &:focus{
            &{
                opacity: 1;
            }
        }
    }

    &__item,
    &__selected{
        display: block;
        font-size: $f-mid;

        font-family: $f-headings;
        font-weight: 400;
    }

    &__item{
        transition: opacity 0.15s cubic-bezier(0.23, 1, 0.32, 1);
        opacity: 0.5;
        margin-bottom: 10px;

        outline: none;

        &:hover,
        &:focus{
        opacity: 1;
        }
    }
}

.hamburger{
    width: 40px;
    height: 40px;
    margin-top: -15px;
    margin-bottom: -15px;
    margin-left: -10px;
    // padding: 10px;
    // background: rgba(255, 255, 255, 0.1);
    font-size: 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    // border-top: 2px solid $c-white;
    text-indent: -99999px;

    transform: translate3d(0, 0, 0);

    span,
    &:before,
    &:after{
        transition: all 0.2s linear;
    }

    &:before{
        content: '';
        position: absolute;
        top: 50%;
        left: 10px;
        right: 10px;
        height: 2px;
        transform: translateY(-50%);
        background-color: $c-white;
    }

    &:after{
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(5px);
        left: 10px;
        right: 10px;
        height: 2px;
        background-color: $c-white;
    }

    span{
        display: block;
        position: absolute;
        top: 50%;
        left: 10px;
        right: 10px;
        height: 2px;
        background-color: $c-white;
        transform: translateY(-7px);
    }

    .page--menu-is-open &{
        span,
        &:before,
        &:after{
            top: 50%;
            transform: translateY(50%);
        }

        &:after{
            opacity: 0;
        }

        &:before{
            transform: rotate(-45deg);
        }

        span{
            transform: rotate(45deg);
        }
    }
}

.g{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-left: -$s-base;
    margin-top: -$s-base;
    @include breakpoint($b-md){
        margin-left: -$s-xlarge;
        margin-top: -$s-xlarge;
    }




    &--tight{
        @include breakpoint($b-md){
            margin-left: -$s-base;
            margin-top: -$s-base;

            .gi{
                padding-left: $s-base;
                padding-top: $s-base;
            }
        }
    }
}

    .gi{
        padding-left: $s-base;
        padding-top: $s-base;
        @include breakpoint($b-md){
            padding-left: $s-xlarge;
            padding-top: $s-xlarge;
        }
        width: 100%;
        margin-bottom: 0;

        &--half{
            width: 50%;

            &\@sm{ @include breakpoint($b-sm){ width: 50%; } }
            &\@md{ @include breakpoint($b-mid){ width: 50%; } }
            &\@lg{ @include breakpoint($b-lg){ width: 50%; } }
        }

        &--third{
            width: 33.33%;

            &\@sm{ @include breakpoint($b-sm){ width: 33.33%; } }
            &\@md{ @include breakpoint($b-mid){ width: 33.33%; } }
            &\@lg{ @include breakpoint($b-lg){ width: 50%; } }
        }

        &--bottom{
            align-self: flex-end;
        }
    }

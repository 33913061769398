.story-chart-item{

    &__title{
        margin-bottom: $s-tight;
        padding-bottom: $s-tight;
        word-wrap: break-word;
    }

    &__description{
        color: #666;
        font-size: 14px;
    }

    &__keys{
        display: flex;
        flex-wrap: wrap;
        margin-left: -2px;
        margin-top: -2px;
    }

    &__key{
        flex: 1 0 25%;
        padding-left: 2px;
        padding-top: 2px;
        width: 25%;
    }

        &__key-title{
            margin-bottom: $s-tight;
            letter-spacing: -0.05em;
            font-size: 11px;
            @include breakpoint($b-sm){
                font-size: $f-small;
            }

            @at-root{
                :lang(pt) &,
                :lang(es) &,
                :lang(mx) &{
                    font-size: 10px;
                    @include breakpoint($b-sm){
                        font-size: 11px;
                    }
                }
            }
        }

    &__charts{
        // display: flex;
        align-items: flex-end;
        margin-left: -8px;
        margin-top: -8px;

    }

        &__chart{
            flex: 1;
            padding-left: 8px;
            padding-top: 8px;
        }

        &__chart-title{
            display: inline-block;
            margin-top: $s-tight;
            // color: $c-dark;
            line-height: 1;
            font-size: $f-small;
            text-align: center;
        }

    &__number{
        font-family: $f-condensed;
        font-size: 130px;
        display: block;
        line-height: 0.76;
        color: #555;
        letter-spacing: -0.05em;
        // border: 1px solid red;
        text-align: center;
        height: 97px;
        text-indent: -0.08em;

        + .story-chart-item__chart-title{
            color: #555;
        }
    }


    &--viz1{
        .story-chart-item__title{
            border-bottom:  2px solid currentColor;
        }
    }

    &--viz2{
        display: flex;
        margin-top: -$s-small;
        margin-left: -$s-small;

        .story-chart-item__keys{
            flex: 1;
            padding-top: $s-small;
            padding-left: $s-small;
        }

        .story-chart-item__key{
            margin-top: 5px;
        }

        .story-chart-item__header{
            flex: 1.333;
            padding-top: $s-small;
            padding-left: $s-small;
            max-width: 300px;
        }

        .story-chart-item__title{
            font-size: $f-mid;

            font-family: $f-headings;
            font-weight: 400;
        }

        .bar-chart{
            margin-bottom: $s-tight;
        }


        // Charts with multiple groups
        &.story-chart-item--has-keys{
            flex-direction: column-reverse;
            @include breakpoint(568px){
                flex-direction: row-reverse;
            }


            .story-chart-item__header{
                flex: 0.5;
            }

            .story-chart-item__key{
                margin-top: -11px;
                margin-bottom: 11px;
            }

            .mega-number{
                font-size: 85px;
            }
        }
    }

    &--viz3{

        margin-bottom: $s-large;

        .story-chart-item__title{
            border-bottom:  2px solid currentColor;
        }

        .story-chart-item__keys{
            margin-left: -$s-small;
            margin-top: -$s-small;
            // justify-content: space-around;
        }

            .story-chart-item__charts{
                display: flex;
                align-items: flex-start;
            }

            .story-chart-item__key{
                padding-left: $s-small;
                padding-top: $s-small;

                &--has-number{

                    .story-chart-item__chart{

                        &:first-child{
                            flex: 0.8;
                            // margin-top: 11px;
                        }
                    }

                    .bar-chart__wrapper{
                        height: 97px;
                    }

                    .story-chart-item__key-title{
                        padding-bottom: 2px;
                        border-bottom: 2px solid;
                    }
                }
            }

            &.story-chart-item--has-keys{
                .story-chart-item__title{
                    font-size: $f-base;
                }
            }

            .story-chart-item--keys-1 &{

                .story-chart-item{

                }
            }

            .story-chart-item--keys-3 &{

                .story-chart-item__keys{
                    justify-content: space-between;
                }

                .story-chart-item__key{
                    flex: 0 0 50%;

                    @include breakpoint(550px){
                        flex: 0 0 33.33%;
                    }

                    @include breakpoint($b-lg){
                        flex: 0 0 25%;
                    }
                }

            }

            .story-chart-item--keys-4 &{
                .story-chart-item__key{
                    flex: 0 0 50%;

                    @include breakpoint(550px){
                        flex: 0 0 25%;
                    }

                    @include breakpoint($b-md){
                        flex: 0 0 50%;
                    }

                    @include breakpoint(800px){
                        flex: 0 0 25%;
                    }

                    @include breakpoint($s-max-width){
                        flex: 0 0 25%;
                    }
                }

            }

            .story-chart-item__chart-title{
                display: block;
            }


    }

    &--viz4{
        display: flex;
        // align-items: center;
        margin-top: -$s-small;
        margin-left: -$s-small;

        .story-chart-item__keys{
            flex: 1;
            // justify-content: flex-start;

            padding-top: $s-small;
            padding-left: $s-small;
        }

        .story-chart-item__key{
            margin-top: 5px;
        }

        .story-chart-item__header{
            flex: 1.333;
            padding-top: $s-small;
            padding-left: $s-small;
            max-width: 300px;
        }

        .story-chart-item__title{
            font-size: $f-mid;

            font-family: $f-headings;
            font-weight: 400;
        }

        .bar-chart{
            margin-bottom: $s-tight;
        }


        // Charts with multiple groups
        &.story-chart-item--has-keys{
            flex-direction: column-reverse;
            @include breakpoint(568px){
                flex-direction: row-reverse;
            }


            .story-chart-item__header{
                flex: 0.5;
            }

            .story-chart-item__keys{
                display: flex;
                width: 100%;
                //border: 1px solid red;
                align-items: flex-start;
                // justify-content: flex-end;
                flex-wrap: nowrap;
            }

            .story-chart-item__key{

                margin-top: -15px;
                margin-bottom: 20px;

                @include breakpoint(568px){
                    margin-top: 0;
                    margin-bottom: 0;
                }

                float: left;
                width: 25%;

                max-width: 110px;
                // border: 1px solid blue;
            }
        }


        &.story-chart-item--has-keys{
            .donut{
                // max-width: 100px;
            }


        }

        .mega-number{
            font-size: 50px;

            @media(min-width: $b-menu-switch ) AND (max-width: $b-lg){
                font-size: 40px;
            }
        }

        &:not(.story-chart-item--has-keys){
            .mega-number{
                font-size: 50px;
            }

        }
    }

    &--viz5{
        .story-chart-item__title{
            border-bottom:  2px solid currentColor;
        }

        .story-chart-item__keys{
            // display: flex;
            // flex-wrap: wrap;
            // align-items: flex-start;
        }


        &.story-chart-item--has-keys{

            .story-chart-item__keys{
                // display: flex;
                // flex-wrap: wrap;
            }

            .story-chart-item__key{
                width: 50%;
                flex: 0 0 auto;
            }


        }

        .mega-number{
            font-size: 70px;
        }

        &.story-chart-item--has-keys{

            .mega-number{
                font-size: 40px;
            }

            &.story-chart-item--is-odd{
                .story-chart-item__key{
                    width: 33.33%;
                    flex: 0 1 auto;
                }

                .mega-number{
                    font-size: 26px;
                }
            }
        }
    }

}

.ie9{
    .header__wrapper{
        display: table;
        width: 100%;
    }

    .header__logo,
    .header__locale,
    .header__nav{
        display: block;
        float: right;
        // display: table-cell;
    }

    .header__logo{
        float: left;
        display: inline-block;
        width: 500px;
    }


    .gi{
        float: left;
    }

    .country-selector__country{
        float: left;
    }


    .country-selector__title{
        padding-top: 4px;
        overflow: hidden;
        span{ float: left; }
        .icon{ float: right; }
    }


    .page-header{

        &:before{
            background-color: #000;
            opacity: 0.5;
        }

        &__title{
            position: absolute;
            bottom: 20px;
            left: 50%;
            display: block;
            transform: translateX(-50%);
            display: inline-block;
        }
    }

    .page__wrapper-top{
        display: table;
    }

    .page__menu,
    .page__wrapper{
        display: table-cell;
        vertical-align: top;
    }

    .page__menu:before{
        display: none;
    }

    .page__wrapper{
        display: table-cell;
    }


    .story--tech{
        .story__header{ &:after{ background: rgba($c-tech, 1); } }
    }
    .story--consumer{
        .story__header{ &:after{ background: rgba($c-consumer, 1); } }
    }
    .story--family{
        .story__header{ &:after{ background: rgba($c-family, 1); } }
    }


    .story-chart__items{
        overflow: hidden;
    }

    .story-chart__item{
        float: left;
    }

    .story-chart__key{
        overflow: hidden;
    }

    .story-chart-item__key{
        float: left;
    }

    .sources{
        width: 100%;

        &__source{
            overflow: hidden;
        }

        &__title,
        &__value{
            float: left;
        }

        &__value{
            width: 75%;
        }
    }

}

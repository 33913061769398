.sources{
    font-size: $f-small;
    color: lighten($c-dark, 20%);
    width: 100%;

    &__source{

        @include breakpoint($b-mid){
            margin-left: -$s-small;
            margin-top: -$s-small;
            display: flex;
        }

        margin-bottom: $s-small;

        &--print-only{
            display: none;
        }
    }

        &__title,
        &__value{
            @include breakpoint($b-mid){
                width: 33.333%;
                padding-left: $s-small;
                padding-top: $s-small;
            }

            @include breakpoint($s-max-width){
                width: 25%;
            }
        }

        &__value{
            flex: 1;
        }

        &__title{
            font-family: $f-headings;
            font-weight: 400;
        }
}

.c-tech{
    color: $c-tech;
}

.c-consumer{
    color: $c-consumer;
}

.c-family{
    color: $c-family;
}

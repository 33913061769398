.link{
    border-bottom: 2px solid $c-highlight;
    color: rgba($c-black, 0.75);
    transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);

    &:hover{
        color: $c-dark;
    }
}

.styled-link a{
    color: $c-family;
    border-bottom: 2px solid;
}

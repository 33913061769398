.tooltip {
    background-color: $c-highlight;
    color: $c-black;
    padding: 10px;
    opacity: 0.99;
    font-size: $f-base;
    &:after {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        bottom: 100%;
        left: 8px;
        border-top: 0;
        border-bottom: 10px solid $c-highlight;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        z-index: -1;
    }
}

.tooltip-transition-appear {
    opacity: 0.01;
    transform: translateY(10%);
}

.tooltip-transition-appear.tooltip-transition-appear-active {
    opacity: 0.99;
    transform: translateY(0%);
    transition: all 0.3s 0.1s cubic-bezier(0.23, 1, 0.32, 1);
}

.tooltip-transition-leave {
    opacity: 0.99;
}

.tooltip-transition-leave.tooltip-transition-leave-active {
    opacity: 0.01;
    transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}

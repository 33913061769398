.page-content {
  color: $c-grey;

  // min-height: calc(100vh - #{$s-xlarge}) ;
  // display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include breakpoint($b-md) {
    font-size: $f-mid;
  }

  &--small {
    // font-size: $s-base;
  }

  h2 {
    font-size: $f-mid;
    @include breakpoint($b-md) {
      font-size: $f-large;
    }
  }

  &__header {
    width: 100%;
    z-index: 1;
    position: relative;
    color: $c-white;
    padding: $s-xlarge $s-small;
    // margin-bottom: $s-large;
    @include breakpoint($b-md) {
      padding: $s-xlarge * 1.5 $s-large;
    }

    min-height: 20vh;

    transform: translateZ(0);
    background-position: center center;
    background-size: cover;
    // display: flex;

    h1 {
      font-size: $f-large;
      @include breakpoint($b-md) {
        font-size: $f-xlarge;
      }
    }

    h2 {
      font-size: $f-base;
      @include breakpoint($b-md) {
        font-size: $f-large;
      }
    }

    &:after,
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
      background-image: $g-all;
      @include breakpoint($s-max-width) {
        width: calc(((100vw - #{$s-max-width}) / 2) + #{$s-max-width} - 275px);
      }
    }

    .sub-heading {
      display: block;
      font-family: $f-body;
      font-weight: normal;
    }

    &--full {
      &:after {
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
        @include breakpoint($s-max-width) {
          width: 100vw;
        }
      }
    }

    &--with-icons {
      padding-bottom: $s-xlarge * 1.5;

      &:after {
        background-image: url("/images/header_strip.svg");
        background-repeat: repeat-x;
        background-size: 800px 58px;
        background-position: center bottom;
        bottom: -9px;
      }
    }
  }

  &__body {
    padding: $s-large $s-small $s-large;
    @include breakpoint($b-md) {
      padding: $s-xlarge $s-large $s-xlarge;
    }

    flex: 1;

    &--small {
      max-width: 820px;
    }

    &--tight {
      padding-top: 0;
    }
  }

  &__footer {
    width: 100%;
    padding: $s-xlarge $s-small $s-xlarge;
    padding-top: 0;
    @include breakpoint($b-md) {
      padding: $s-xlarge $s-large $s-xlarge;
      padding-top: 0;
    }
  }

  &__title {
    letter-spacing: -0.02em;

    font-size: $f-large !important;
    @include breakpoint($b-md) {
      font-size: $f-xlarge !important;
    }
  }

  &__lede {
    letter-spacing: -0.05em;
    font-size: $f-mid;
    @include breakpoint($b-md) {
      font-size: $f-large;
    }
  }

  &__story {
    display: flex;
    flex-direction: row-reverse;
    margin-left: -$s-base;
    margin-top: -$s-base;

    // font-size: $f-medium;

    h3 {
      font-size: $f-mid;
      @include breakpoint($b-mid) {
        font-size: $f-large;
      }
      font-weight: normal;
      margin-bottom: $s-small;
    }

    img {
      margin-bottom: $s-base;
    }

    &--tech {
      h3 {
        color: $c-tech;
      }
    }
    &--consumer {
      h3 {
        color: $c-consumer;
      }
    }
    &--family {
      h3 {
        color: $c-family;
      }
    }

    &-chart {
      padding-left: $s-base;
      padding-top: $s-base;
      width: 33.33%;
    }

    &-copy {
      flex: 1;
      width: 66.33%;
      padding-left: $s-base;
      padding-top: $s-base;
    }
  }

  &__illustration {
    margin-bottom: $s-medium;
    @include breakpoint($b-mid) {
      margin-bottom: 0;
    }

    h3 {
      font-size: $f-large;
      text-align: center;
      @include breakpoint($b-mid) {
        font-size: $f-large;
      }
      font-weight: normal;
    }

    img {
      display: block;
      margin: 0 auto;
      margin-bottom: $s-base;
      // max-width: 180px;
      // @include breakpoint($b-mid){
      //     max-width: none;
      // }
    }

    p {
      font-size: $f-medium;
      @include breakpoint($b-mid) {
        font-size: $f-base;
      }
    }

    &--tech h3 {
      color: $c-tech;
    }
    &--consumer h3 {
      color: $c-consumer;
    }
    &--family h3 {
      color: $c-family;
    }
  }
}

.country-selector{

    &__title{
        font-family: $f-body;
        line-height: 1.4;
        border-bottom: 2px solid $c-highlight;
        font-weight: normal;
        letter-spacing: -0.05em;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 10px;
        line-height: 1;
        padding-bottom: 5px;

        span:first-child{
            //flex: 1 0 0%;
            display: block;
        }

        font-size: $f-mid;
        @include breakpoint($b-lg){
            font-size: 25px;
        }

        @include breakpoint(980px){
            font-size: $f-large;
        }

        letter-spacing: -0.05em;

        .icon{
            display: block;
            margin-left: 10px;
            max-width: 32px;
        }
    }

    &__countries{
        display: flex;
        flex-wrap: wrap;
        // align-items: stretch;
        margin-left: -10px;
        margin-top: -10px;
    }

        &__country{
            padding-left: 10px;
            padding-top: 10px;
            width: 33.3333%;
            // font-size: $f-base;
            text-align: center;
            display: flex;
        }

    &__button{
        display: block;
        padding: 10px 2px;
        border: 2px solid $c-highlight;
        font-family: $f-headings;
        transition: background 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        color: $c-grey;
        visibility: middle;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex: 1;

        font-size: 14px;
        @include breakpoint($b-md){
            font-size: 15px;
        }
        @include breakpoint($b-lg){
            font-size: $f-base;
            padding-top: 15px;
            padding-bottom: 15px;
        }

        &:hover{
            background: rgba($c-highlight, 0.5);
        }
    }
}

.story-chart{
    padding-bottom: $s-base;
    // word-wrap: break-word;

    .story--tech &{ color: $c-tech; }
    .story--family &{ color: $c-family; }
    .story--consumer &{ color: $c-consumer; }

    &__heading{
        font-size: $f-mid;
        color: #666;
        padding-bottom: $s-medium;
        position: relative;
        border-bottom: 2px dotted lighten($c-grey, 40);
        display: flex;
        justify-content: space-between;
        align-items: center;

        span{
            flex: 1;
            display: inline-block;
        }

        .icon{
            width: 25px;
            margin-left: 15px;
        }

    }

    &__items{
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin-left: -$s-small;
        margin-top: -$s-small;

        padding-bottom: $s-large;
        margin-bottom: $s-large;
        position: relative;

        opacity: 1;

        &:after{
            content: '';
            position: absolute;
            left: $s-small;
            right: 0;
            bottom: -1px;
            border-bottom: 2px dotted lighten($c-grey, 40x);
        }
    }


        &__item{
            width: 50%;
            padding-left: $s-small;
            padding-top: $s-small;

            @include breakpoint(500px){
                width: 33.33%;
            }

            @include breakpoint($b-menu-switch){
                width: 50%;
            }

            @include breakpoint($b-lg){
                width: 33.33%;
            }

            @include breakpoint($s-max-width){
                width: 25%;
            }
        }

    &__sources{}

    &--2{

        .story-chart__items{
            align-items: flex-start;
        }
        .story-chart__item{

            width: 100%;
            @include breakpoint($b-mid){
                width: 50%;
            }

            &--has-keys{
                width: 100%;
            }
        }
    }

    &--3{
        .story-chart__item{
            width: 50%;
            padding-left: $s-small;
            padding-top: $s-small;

            @include breakpoint($s-max-width){
                width: 25%;
            }
        }



        .story-chart__item{

            &.story-chart-item--keys-1{
                width: 50%;
                @include breakpoint(600px){
                    width: 33.33%;
                }

                @include breakpoint($b-lg){
                    width: 25%;
                }
            }

            &--has-keys{


                &.story-chart-item--keys-2{
                    width: 100%;
                    @include breakpoint(600px){
                        width: 50%;
                    }

                    @include breakpoint($b-md){
                        width: 100%;
                    }

                    @include breakpoint($b-lg){
                        width: 50%;
                    }
                }

                &.story-chart-item--keys-3{
                    width: 100%;
                    @include breakpoint(600px){
                        width: 100%;
                    }

                    @include breakpoint($b-md){
                        width: 100%;
                    }

                    @include breakpoint($b-lg){
                        width: 100%;
                    }
                }

                &.story-chart-item--keys-4{
                    width: 100%;
                    @include breakpoint(600px){
                        width: 100%;
                    }

                    @include breakpoint($b-md){
                        width: 100%;
                    }

                    @include breakpoint($b-lg){
                        width: 100%;
                    }
                }
            }
        }
    }

    &--4{

        .story-chart__items{
            align-items: flex-start;
        }
        .story-chart__item{

            width: 100%;
            @include breakpoint($b-mid){
                width: 50%;
            }

            &--has-keys{
                width: 100%;
            }
        }
    }

    &--5{

        .story-chart__item{
            width: 50%;
            padding-left: $s-small;
            padding-top: $s-small;

            @include breakpoint($b-sm){
                width: 33.33%;
            }

            @include breakpoint($b-lg){
                width: 25%;
            }
        }
    }
}

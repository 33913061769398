.keys{
    color: $c-tech;
    font-size: $f-medium;

    margin-left: -$s-tight;
    margin-top: -$s-tight;

    padding-bottom: $s-medium;
    margin-bottom: $s-medium;

    position: relative;

    &:after{
        content: '';
        position: absolute;
        top: 100%;
        left: $s-tight;
        right: 0;
        border-bottom: 2px dotted lighten($c-grey, 40);
    }

    .story--tech &{ color: $c-tech; }
    .story--family &{ color: $c-family; }
    .story--consumer &{ color: $c-consumer; }

    &__title{
        margin-bottom: $s-tight;
        margin-left: $s-tight;
        font-size: inherit;
    }

    &__item{
        padding: 2px 2px;
        border: 2px solid;
        margin-left: $s-tight;
        margin-top: $s-tight;
        background-clip: border-box;
        transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        position: relative;
        z-index: 1;
        overflow: hidden;

        @include breakpoint($b-mid){
            padding-left: 5px;
            padding-right: 5px;
        }

        outline: none;

        .story--tech &{ border-color: $c-tech; }
        .story--family &{ border-color: $c-family; }
        .story--consumer &{ border-color: $c-consumer; }

        &:hover,
        &:focus{

            color: $c-white;

            .story--tech &{ background-color: rgba($c-tech, .75); }
            .story--family &{ background-color: rgba($c-family, .75); }
            .story--consumer &{ background-color: rgba($c-consumer, .75); }
        }

        &.is-active,
        &.is-active:hover{
            color: $c-white;
            border-color: transparent;

            .story--tech &{ background-color: $c-tech; }
            .story--family &{ background-color: $c-family; }
            .story--consumer &{ background-color: $c-consumer; }
        }

    }
}

.group-selector{

    width: 100%;
    // border: 1px solid blue;
    display: flex;
    justify-content: space-between;
    font-size: $s-small;
    @include breakpoint($b-mid){
        display: block;
        font-size: $f-medium;
    }

    margin-bottom: -$s-base;

    &__item{
        position: relative;
        padding-bottom: $s-base;
        opacity: 0.6;
        transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
        word-wrap: break-word;

        @include breakpoint($b-mid){
            &:not(:last-child){
                margin-right: $s-medium;
            }
        }

        outline: none;

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%) translateY(100%);
            width: 0px;
            height: 0px;
            // background: $c-white;
            border-bottom: 10px solid $c-white;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
        }

        &:hover,
        &:focus{
            opacity: 1;
            &:after{
                transform: translateX(-50%) translateY(70%);
            }
        }

        &.is-active{
            opacity: 1;
            &:after{
                transform: translateX(-50%) translateY(10%);
            }
        }
    }
}

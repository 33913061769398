.transition-height{
    overflow-x: visible;
    overflow-y: hidden;
    transform: translate3d(0, 0, 0);

    &.has-sized{
        transition: max-height 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.98);

        &:not(.is-active){
            max-height: 0 !important;
        }
    }

    &.without-transition{
        transition: none;
    }
}

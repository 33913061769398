.page-header {
  z-index: 1;
  position: relative;
  color: $c-white;
  width: 100%;
  padding: $s-base;
  min-height: 50vw;
  @include breakpoint($b-sm) {
    height: 30vh;
    min-height: 30vh;
  }

  @include breakpoint($b-lg) {
    min-height: 50vh;
    padding: $s-xlarge;
  }

  display: flex;
  align-items: flex-end;
  // display: none;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
    z-index: -1;
    width: 100vw;
    transform: translateX(-50%);
    background-size: cover;
    background-position: center;
    background-image: url("/images/homepage.jpg");
  }

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    right: 0;
    top: 0;
    bottom: 0;
    background: transparent;
    width: 100vw;
    transform: translateX(-50%);
    background: $g-transparent;
  }

  &__title {
    display: block;
    margin: auto;
    text-align: center;
    line-height: 0.85;
    position: relative;
    padding-top: 20px;

    font-size: 35px;
    @include breakpoint($b-sm) {
      font-size: 45px;
    }

    @include breakpoint($b-md) {
      margin-bottom: 20px !important;
      font-size: 80px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 15%;
      right: 15%;
      bottom: 100%;
      height: 3px;
      background: $c-highlight;
    }
  }
}

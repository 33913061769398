/**
 * TYPOGRAPHY
 */

@import "fonts";


// Base text
body{
    font-family: $f-body;
    font-weight: normal;

    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
}




// Standard Links
a{
    color: currentColor;
    text-decoration: none;
    &:hover{
        color: currentColor;
    }
}




// Base Headings
h1,
h2,
h3,
h4,
h5,
h6{
    padding: 0;
    line-height: 1;
    font-family: $f-headings;
    font-weight: 400;
}

h1{ font-size: $f-xlarge; }
h2{ font-size: $f-large; }
h3{ font-size: $f-medium; }
h4{ font-size: $f-base; }
h5{ font-size: $f-base; }
h6{ font-size: $f-base; }


sup, sub {
   vertical-align: baseline;
   position: relative;
   top: -0.4em;
}
sub { top: 0.4em; }



hr{
    height: 0px;
    border: none;
    border-bottom: 2px dotted lighten($c-grey, 40);
}


strong{
    font-family: $f-headings;
    font-weight: normal;
}


dd,
dt{
    margin: 0;
}

dt{
    margin-bottom: $s-tight;
    font-family: $f-headings;
}

.menu{

    .transition-height{
        margin-right: -35px;
        padding-right: 35px;
    }

    &__level{
        padding-bottom: $s-base;
        position: relative;


        &--level-0{
            > .menu__item{
                position: relative;
                font-family: $f-headings;
                font-weight: 400;
                font-size: $f-mid;
                padding-top: $s-small;
                padding-bottom: $s-large;

                // Arrows for first level
                &.has-children{
                    &:after{
                        content: '';
                        width: 0;
                        height: 0;
                        position: absolute;
                        border-top: 10px solid;
                        border-left: 7px solid transparent;
                        border-right: 7px solid transparent;
                        top: 22px;
                        right: 0;
                        transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);

                    }

                    &.is-active:after{
                        transform: rotate(180deg);
                    }
                }

                // Colours of arrows
                &--item-tech:after{  color: $c-tech; }
                &--item-family:after{  color: $c-family; }
                &--item-consumer:after{  color: $c-consumer; }
            }
        }

        &--level-1{

            &:last-child{
                padding-bottom: 20px;
            }

            > .menu__item{
                padding-top: 5px;
                padding-bottom: $s-base;
                position: relative;
                // color: rgba($c-white, 0.5);

                &:before{
                    height: 2px;
                }
            }

            .has-children{

                + .transition-height{
                    // transform: translateY(-10px);
                }
            }
        }

        &--level-2{

            position: relative;
            z-index: 999;
            padding-bottom: 10px;

            > .menu__item{
                display: inline-block;
                font-size: $f-small;
                padding: 2px $s-tight;
                border-width: 2px;
                border-style: solid;
                width: auto;
                margin-right: $s-tight;
                margin-bottom: $s-tight;
                vertical-align: bottom;

                opacity: 0;
                transform: scale(0.9);
                &:before{ display: none; }


                &--item-tech:focus, &--item-tech:hover{  background-color: rgba($c-tech, .5); }
                &--item-family:focus, &--item-family:hover{  background-color: rgba($c-family, .5); }
                &--item-consumer:focus, &--item-consumer:hover{  background-color: rgba($c-consumer, .5); }

                &--item-tech.is-active{  background-color: $c-tech; }
                &--item-family.is-active{  background-color: $c-family; }
                &--item-consumer.is-active{  background-color: $c-consumer; }
            }

            .is-active > & > .menu__item {
                opacity: 1;
                transform: scale(1);
                transition-delay: 0.1s;
            }
        }
    }

    &__item{
        display: block;
        width: 100%;
        text-align: left;
        font-size: $f-medium;
        transition: all 0.2s linear;

        opacity: 0.7;
        transition: all 0.2s ease-out;

        &:before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: $s-tight;
            background-color: #fff;
            transition: all 0.4s ease-out;
        }

        &--item-tech{
            border-color: $c-tech;
            &:before{
                background: $c-tech;
            }
        }

        &--item-family{
            border-color: $c-family;
            &:before{
                background: $c-family;
            }
        }

        &--item-consumer{
            border-color: $c-consumer;
            &:before{
                background: $c-consumer;
            }
        }

        // Active states
        &.is-active{
            color: $c-white;
            opacity: 1;

            &:not(.has-children){
                &:before,
                &:hover:before{
                    transition-delay: 0.2s;
                    right: -$s-large;
                }
            }
        }

        // Hover state
        &:hover,
        &:focus{
            outline: none;
            color: $c-white;
            opacity: 1;

            &:before{
                right: -10px;
            }
        }
    }

    // Small fade to hide
    &__fade{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }

}

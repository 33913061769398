.bar-chart{

    transform: translate3d(0, 0, 0);

    .story--tech &,
    .page-content__story--tech &{ color: $c-tech; }
    .story--family &,
    .page-content__story--family &{ color: $c-family; }
    .story--consumer &,
    .page-content__story--consumer &{ color: $c-consumer; }

    &__wrapper{
        position: relative;
        height: 154px;
        overflow: hidden;

        background: lighten(desaturate($c-tech, 50%), 50%);
        background-image: repeating-linear-gradient(
            45deg,
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.5) 1px,
            rgba(255, 255, 255, 0) 1px,
            rgba(255, 255, 255, 0) 8px
        );

        transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);

        .story--tech &,
        .page-content__story--tech &{ background-color: lighten(desaturate($c-tech, 50%), 50%); color: $c-tech; }
        .story--family &,
        .page-content__story--family &{ background-color: lighten(desaturate($c-family, 50%), 25%); color: $c-family; }
        .story--consumer &,
        .page-content__story--consumer &{ background-color: lighten(desaturate($c-consumer, 50%), 20%); color: $c-consumer; }
    }


    &__percentage{
        display: block;
        background: currentColor;
        // width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        transition: height 0.5s cubic-bezier(0.23, 1, 0.32, 1),
                    width 0.5s cubic-bezier(0.23, 1, 0.32, 1),
                    opacity 0.33s ease-out,
                    transform 1s cubic-bezier(0.23, 1, 0.32, 1);

        &:after{
            content: '';
            position: absolute;
            background: inherit;
            top: 0%;
            left: -100%;
            right: 0%;
            bottom: -100%;
            z-index: -1;
        }
    }

    &-appear{
        .bar-chart__percentage{ opacity: 0.01; transform: translateY(100%); }


        &.bar-chart--horizontal{ .bar-chart__percentage{ transform: translateX(-100%); } }
        &-active{
            .bar-chart__percentage{ opacity: 0.99; transform: translateY(0%); }
            &.bar-chart--horizontal{ .bar-chart__percentage{ transform: translateX(0); } }
        }
    }

        &__percentage-num{
            color: #fff;
            color: currentColor;
            display: inline-block;
            padding: 3px;
            transition: all 0.5s ease-in-out;
            transform: translateY(-100%);

            font-size: 14px;
            @include breakpoint($b-mid){
                font-size: 15px;
            }

            &--inside{
                color: $c-white;
                transform: translateY(0%);
            }
        }


    &--horizontal{
        height: 15px;

        .bar-chart__percentage{ top: 0; }

        .bar-chart__percentage-num{
            transform: none;
            position: absolute;
            top: 0;
            left: 100%;

            &--inside{ transform: translateX(-100%); }
        }

        &.bar-chart--has-number{
            height: 25px;
        }
    }

    &--has-mega-number{
        margin-bottom: 5px;
    }

    &--tall{
        height: 35px;
    }

    &--solid{
        position: relative;

        .bar-chart__percentage{
            // height: 100% !important;
        }

        .bar-chart__percentage-mega-num{
            // display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            color: #fff;
            transform: translate(-50%, -50%);
            font-size: 6em;
        }
    }

    &--no-data{
        opacity: 0.7;

        .story--tech &,
        .page-content__story--tech &{ background-color: lighten(desaturate($c-tech, 50%), 20%); color: $c-tech; }
        .story--family &,
        .page-content__story--family &{ background-color: lighten(desaturate($c-family, 50%), 20%); color: $c-family; }
        .story--consumer &,
        .page-content__story--consumer &{ background-color: lighten(desaturate($c-consumer, 50%), 24%); color: $c-consumer; }

    }

    &--full{
        .bar-chart__percentage-num{
            font-size: 13px;

        }
    }
}


.button{
    color: $c-grey;
    font-size: $f-base;
    font-family: $f-headings;
    cursor: pointer;

    display: inline-block;
    padding-bottom: $s-small;
    border-bottom: 2px dotted lighten($c-grey, 40);
    display: flex;
    align-items: center;

    &__icon{
        transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
        margin-left: $s-small;
        svg{
            height: 16px;
            width: 16px;
        }
    }

    &:hover{
        .button__icon{
            display: inline-block;
            transform: translateX(5px);
        }
    }
}

.page-footer{
    color: $c-grey;
    display: flex;
    justify-content: space-between;
    font-size: $f-medium;
    @include breakpoint($b-md){
        font-size: $f-base;
    }

    span{
        display: inline-block;
    }
}

.header{
    background: $c-black;
    color: #fff;

    font-size: 12px;

    font-family: $f-headings;
    font-weight: 400;

    position: relative;

    height: 52px;

    a{
        color: inherit;
        text-decoration: none;
    }


    &__wrapper{
        max-width: $s-max-width;
        margin: 0 auto;
        display: flex;
        align-items: center;
        padding: $s-small $s-small;

        @include breakpoint($b-md){
            padding-left: $s-large;
            padding-right: $s-large;
        }

        // > * {
        //     outline: 1px solid red;
        // }
    }

    &__logo{
        line-height: 1;
        margin-bottom: 0;
        font-size: $f-mid;
        flex: 1;
    }

    &__logo-image{
        height: 20px;
        width: auto;
        margin-right: $s-small;
    }

    &__logo-message{
        margin-left: $s-small;
        font-size: $f-mid;
        position: relative;

        &:before{
            content: '';
            width: 1px;
            position: absolute;
            left: -$s-small;
            top: 0;
            bottom: 0;
            background: $c-highlight;
        }

        display: none;
        @include breakpoint(620px){
            display: inline-block;
        }
    }

    &__hamburger{
        margin-right: $s-tight;
        transition: all 0.2s cubic-bezier(0.23, 1, 0.32, 1);
        @include breakpoint($b-menu-switch){
            display: none;
        }

        &.is-hidden{
            transform: scale(0.5);
            opacity: 0.5;
        }
    }

    &__locale{
        margin-left: auto;
    }

    &__nav-item{
        display: block;
        margin-left: $s-tight;
        // padding-top: $s-tight;
        padding-bottom: $s-tight;
        border-bottom: 3px solid $c-highlight;

        @include breakpoint($b-md){
            font-size: $f-medium;
        }
    }

    &__tooltip{
        position: absolute;
        left: 5px;
        top: 100%;
        margin-top: 5px;
        cursor: pointer;

        @include breakpoint($b-md){
            left: 27px;
        }
    }
}

html{
    -webkit-text-size-adjust: none;
}

body{
    margin: 0;
    padding: 0;
    width: 100%;
}

html,
body,
#root{
    @media (max-width: $b-menu-switch - 1){
        height: 100%;
    }
}


// Shared vertical rhythm - csswizardry.com/2012/06/single-direction-margin-declarations
h1, h2, h3, h4, h5, h6, hgroup,
ul, ol, dl,
blockquote,p,address,
table,
fieldset,figure,
pre,
.g{
    margin-top: 0;
    margin-bottom: $s-base;

    &:last-child,
    &.last{ // remove from last if required
       margin-bottom: 0;
    }
}

// Border Box all the things
*{
    box-sizing: border-box;
}

// Makes images responsive by default
img,
svg{
    // display: inline-block;
    vertical-align: bottom;
    max-width: 100%;
}


// IE 10/11 svgs
[src*=".svg"]{
    width: 100%;
}



// Remove the border around images in IE
a{
    img{
        border: 0;
    }
}

//  Simplify buttons
button{
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
    color: inherit;
    text-align: inherit;
    // outline: none;
}

$menu-width: 275px;

.page-wrapper{
    height: 100%;
}

.page{
    position: relative;
    // display: flex;
    // flex-direction: column;

    height: 100%;
    @media (min-width: $b-menu-switch){
        min-height: 100vh;
    }
    overflow: hidden;
    z-index: 1;

    &__header{
        z-index: 999;
        position: relative;
    }

    &__wrapper-top{
        display: flex;
        // flex: 0 1 100%;
        // z-index: -1;
        max-width: $s-max-width;
        margin: 0 auto;
        height: calc(100% - 50px);
    }

    &__menu{
        width: $menu-width;
        min-width: $menu-width;
        z-index: 0;

        min-height: calc(100vh - 50px);

        background: $c-dark;
        color: $c-white;
        padding: $s-large;
        padding-left: $s-small;
        position: relative;

        @include breakpoint($b-md){
            padding-left: $s-large;
        }

        &:before{
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 100%;
            width: 50vw;
            background: inherit;
        }

        @media (max-width: $b-menu-switch){
            position: absolute;
            top: 50px;
            bottom: 0;
            left: 0px;
            overflow-x: hidden;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
        }
    }

    &__wrapper{
        flex: 0 1 100%;
        overflow-x: visible;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        background: #fff;
        transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
        position: relative;
        @include breakpoint($b-menu-switch){
            overflow: visible;
        }

        // Needed for ie10!
        float: left;

    }

    &__body{
        flex: 0 0 auto;
        max-width: 100%;
        position: relative;
    }

    &__overlay{
        position: absolute;
        top: 0;
        left: 100%;
        right: 0;
        bottom: 0;
        background: $c-dark;
        opacity: 0;
        // pointer-events: none;
        z-index: 999999;
        transition: opacity 0.3s ease-out,
                left 0s 0.3s ease-out;
    }

    &--menu-is-open{
        @media (max-width: $b-menu-switch){
            .page__wrapper{
                transform: translateX($menu-width);
            }

            .page__overlay{
                transition: opacity 0.3s ease-out,
                        left 0s ease-out;
                left: 0;
                opacity: 0.4;
            }
        }
    }

    &--no-menu{
        .page__wrapper{
            transform: translateX(0);
        }
    }
}
